<template>
  <div>
    <ayl-berad-nav :nav="nav"></ayl-berad-nav>
    <div class="content-box" style="padding-top: 24px">
      <div class="content-main">
        <ayl-table :table="table" @selection-change="onSelectionChange">
          <div slot="ctrl-button">
            <!-- {{selectionData}} -->
            <el-button class="btn" style="margin-left: 20px" @click="onAdd">+ 新增</el-button>
            <el-button plain @click="onFire" style="float:right">离职</el-button>
            <!-- <el-button plain @click="onExport" style="float:right">导出</el-button> -->
          </div>
        </ayl-table>
      </div>
    </div>
    <el-dialog title="照片附件" :visible.sync="carImageDialogVisible" width="1000px" center>
      <div class="content-main">
        <div
          class="order-img"
          :style="{'margin-right': (idx+1)%3===0?'0px':''}"
          v-for="(item, idx) in personnelImage"
          :key="idx"
        >
          <el-image
            :preview-src-list="[item]"
            :src="item"
            fit="cover"
            style="width: 100%;height: 100%;border-radius: 4px"
          ></el-image>
          <i class="el-icon-error close-icon" v-if="item.isClear" @click="clickClearImg(idx)"></i>
        </div>
      </div>
    </el-dialog>
    <ayl-dialog
      :visible.sync="isShowDialog"
      :viewUrl="viewUrlForDialog"
      v-model="dataForDialog"
      :async="true"
      :showBottomBtn="false"
      :width="widthForDialog"
    ></ayl-dialog>
  </div>
</template>

<script>
export default {
  data() {
    const vm = this;
    return {
      nav: [{ name: "人员管理" }],

      //弹窗有关
      viewUrlForDialog: ``,
      isShowDialog: false,
      dataForDialog: null,
      widthForDialog: "50%",

      btnLoading: false,
      selectionData: [], //勾选的数据
      carImageDialogVisible: false,
      personnelImage: [],
      table: {
        api: vm.$api_hw.personnelInfoList,
        query: {
          bidId: null,
          sex: null, //性别
          personTypes: [], //人员类型列表
          personStatus: null, //工作状态
          queryContent: null
        },
        // 是否显示分页
        // hidePagination: true,
        // 表格查询的条件组件
        searchData: [
          {
            type: "select",
            title: "性别",
            model: "sex",
            placeholder: "请选择",
            option: vm.$enums_hw.sex.list
          },
          {
            type: "cascader_two",
            title: "人员类型",
            model: "personTypes",
            placeholder: "请选择",
            option: vm.$enums_hw.personnelTypeNew.list,
            // 是否扁平化数据
            flat: [],
            width: "180px"
          },
          {
            type: "select",
            title: "工作状态",
            model: "personStatus",
            placeholder: "请选择",
            option: vm.$enums_hw.personStatus.list
          },
          {
            type: "cascader_radio",
            title: "所属标段",
            model: "bidId",
            placeholder: "请选择",
            option: [],
            width: "180px",
            flat: []
          },
          {
            type: "input",
            model: "queryContent",
            placeholder: "姓名/设备号/身份证"
          }
        ],
        columns: [
          {
            type: "selection",
            width: "50px"
          },
          {
            title: "序号",
            width: "50px",
            $index: "index"
          },
          {
            title: "姓名",
            render(h, ctx) {
              return h("span", [
                h(
                  "span",
                  {
                    class: {
                      "table-view-hw": true
                    },
                    on: {
                      click: vm.onInfo.bind(this, ctx.row)
                    }
                  },
                  ctx.row.name || "- -"
                )
              ]);
            }
          },
          {
            title: "设备号",
            key: "deviceName"
          },
          {
            title: "SIM卡号",
            width: "140px",
            showTooltip: true,
            key: "simCardNumber"
          },
          {
            title: "性别",
            key: "sexDes",
            width: "60px"
          },
          {
            title: "身份证号码",
            width: "180px",
            showTooltip: true,
            key: "idCardNumber"
          },
          {
            title: "人员类型",
            key: "personTypeDes"
          },
          {
            title: "所属企业",
            key: "companyName"
          },
          {
            title: "所属标段",
            key: "bidName",
            showTooltip: true
          },
          {
            title: "排班线路/网格",
            key: "routeOrGridName"
          },
          {
            title: "联系方式",
            width: "120px",
            key: "phone"
          },
          {
            title: "工作状态",
            key: "personStatusDes"
          },
          {
            title: "照片附件",
            key: "idImageUrls",
            render(h, ctx) {
              let text = ctx.row.idImageUrls ? "有" : "无";
              return h("span", [h("span", text)]);
            }
          },
          {
            title: "操作",
            width: "215px",
            render(h, ctx) {
              let text1 = ctx.row.deviceName ? "重新匹配" : "匹配";
              let text2 = ctx.row.deviceName ? "解绑设备" : "";
              return h("span", [
                h(
                  "el-button",
                  {
                    style: {
                      color: ctx.row.personStatus === 0 ? "#1ABC9C" : ""
                    },
                    props: {
                      type: "text",
                      disabled: ctx.row.personStatus === 0 ? false : true
                    },
                    on: {
                      click: vm.editDistrict.bind(this, ctx.row)
                    }
                  },
                  "编辑"
                ),
                h(
                  "el-button",
                  {
                    style: {
                      color: ctx.row.personStatus === 0 ? "#1ABC9C" : ""
                    },
                    props: {
                      type: "text",
                      disabled: ctx.row.personStatus === 0 ? false : true
                    },
                    on: {
                      click: vm.matchPeople.bind(this, ctx.row)
                    }
                  },
                  text1
                ),
                h(
                  "el-button",
                  {
                    style: {
                      color: ctx.row.deviceName ? "#f66" : ""
                    },
                    props: {
                      type: "text",
                      disabled: ctx.row.deviceName ? false : true
                    },
                    on: {
                      click: vm.unBind.bind(this, ctx.row)
                    }
                  },
                  text2
                )
              ]);
            }
          }
        ]
      }
    };
  },
  methods: {
    //新增
    onAdd() {
      //传views的url
      this.viewUrlForDialog =
        "/district-management/personnel-info/add-personnel";
      this.widthForDialog = "60%";
      //传参给弹窗
      this.dataForDialog = {};
      this.isShowDialog = true; //显示弹窗
    },

    /**
     * 编辑人员
     */
    editDistrict(val) {
      this.viewUrlForDialog =
        "/district-management/personnel-info/edit-personnel";
      this.widthForDialog = "60%";
      //传参给弹窗
      this.dataForDialog = {
        id: val.personId
      };
      this.isShowDialog = true; //显示弹窗
    },

    /**
     * 人员详情
     */
    onInfo(val) {
      this.viewUrlForDialog =
        "/district-management/personnel-info/info-personnel";
      this.widthForDialog = "60%";
      //传参给弹窗
      this.dataForDialog = {
        id: val.personId
      };
      this.isShowDialog = true; //显示弹窗
    },

    /**
     * 匹配人员
     */
    matchPeople(val) {
      this.viewUrlForDialog =
        "/district-management/personnel-info/match-people";
      this.widthForDialog = "60%";
      //传参给弹窗
      this.dataForDialog = {
        id: val.personId
      };
      this.isShowDialog = true; //显示弹窗
    },

    /**
     * 查看附件照片
     */
    async openImage(val) {
      this.carImageDialogVisible = true;
      this.personnelImage = val.idImageUrlLinks;
      await this.$nextTick(() => {
        this.$search(this.personnelImage);
      });
    },
    onSelectionChange(i) {
      this.selectionData = i.map(d => d.personId);
    },
    onFire() {
      let self = this;
      if (self.selectionData.length === 0) {
        self.$message.warning("请选中至少 1 名员工");
        return;
      } else {
        self.$msgbox
          .confirm("确定要将员工离职吗？", "提示", { type: "warning" })
          .then(async function() {
            self.btnLoading = true;
            try {
              await self.$api_hw.personnelInfo_updatePersonStatus({
                idList: self.selectionData
              });
              self.$notify({
                title: "成功",
                message: "操作成功",
                type: "success"
              });
              self.$nextTick(function() {
                self.$search(this.table);
              });
            } catch (e) {
              // console.error(e);
            }
          })
          .catch(e => {});
        self.btnLoading = false;
      }
    },
    onExport() {},
    // 解除绑定
    unBind(val) {
      this.$confirm("此操作将解除人员和设备的绑定关系，是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.$api_hw.personnelInfo_personGetUnbindDevice({
          id: val.personId
        }).then(res => {
          this.$notify({
            title: "成功",
            message: "解绑成功",
            type: "success"
          });
          this.$search(this.table);
        });
      }).catch(() => {});
    }
  },
  async mounted() {
    let sf = this;
    BUS.$on(BUSEVENT.REFRESH_PERSONNEL_INFO, () => {
      sf.$search(this.table);
    });
    this.table.searchData[3].option = await this.$api_hw.common_getBidNameAndIdList(
      {}
    );
  }
};
</script>

<style lang='sass' scoped>
.btn
  width: 64px
  height: 28px
  border: 1px solid rgba(26,188,156,1)
  border-radius: 4px
  color: #1ABC9C
  
  .drawer-main
    margin: 5px 15px
    .drawer-btn
      width: 92px
      height: 36px
      margin-bottom: 10px
      background: rgba(237,237,237,1)
      border-radius: 2px
      outline: none
      border: 0
      &:hover
        margin-bottom: 10px
        background: rgba(26,188,156,1)
        border-radius: 2px
        border: 0
        color: #fff
      &:before
        margin-bottom: 10px
        background: rgba(26,188,156,1)
        border-radius: 2px
        border: 0
        color: #fff
    .drawer-btn1
      width: 92px
      height: 36px
      margin-bottom: 10px
      background: rgba(26,188,156,1)
      border-radius: 2px
      outline: none
      border: 1px solid transparent
      color: #fff

  .drawer-contract
    margin: 30px 15px 10px
    .drawer-contract-item
      width: 100%
      .drawer-contract-span
        color: #2E3033
    .drawer-contract-item1
      width: 48%
      .drawer-contract-span
        color: #2E3033
  .drawer-hr
    width: 100%
    height: 1px
    background: rgba(232,232,232,1)
  .drawer-map
    margin: 20px 15px 10px
    .drawer-map-title
      color: rgba(46,48,51,1)
  /deep/.el-drawer__header 
      margin-bottom: 15px
      padding-left: 15px
      color: #222
      font-size: 16px
  /deep/.el-button--success 
      color: #FFFFFF
      background-color: rgba(26,188,156,1)
      border-color: rgba(26,188,156,1)

  .order-img
    position: relative
    width: calc((100% - 2 * 13px) / 3)
    height: 138px
    display: inline-block
    margin-right: 13px
    margin-bottom: 14px
    border-radius: 4px
  .close-icon
    position: absolute
    top: -5px
    right: -8px
    font-size: 14px
    cursor: pointer

</style>
